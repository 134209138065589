export default {
  inject: {
    fieldError: {
      default: { value: false },
    },
    fieldDisabled: {
      default: { value: false },
    },
  },
};
